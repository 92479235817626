.society{
  background-color: var(--color-cyan-2);
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-bottom: 8.333rem;
  padding-top: 8.333rem;
}

.society__title{
  font-size: 2.778rem;
}

.society__content{
  font-size: 0.778rem;
  width: 50%;

  p{
    margin-bottom: 18px;
  }
}