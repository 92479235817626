.focus-items{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 33px;
  padding-top: 81px;
}

.focus-item{
  border-right: 1px solid var(--color-blue-3);
  padding: 30px 5%;

  &:last-of-type{
    border-right: none;
  }
}

.focus-item__article{
  font-size: 0.778rem;
}

.focus-item__header{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
}

.focus-item__section{
  font-size: 0.889rem;
}

.focus-item__media{
  margin-bottom: 18px;
}

.focus-item__title{
  font-family: var(--font-secondary);
  text-transform: uppercase;
}

.focus-item__subtitle{
  font-family: var(--font-primary);
  font-weight: 400;
}

.focus-item__content{
  margin-top: 6px;
}

.focus-item__location{
  color: var(--color-blue-3);
  font-family: var(--font-secondary);
  font-size: 0.722rem;
  text-transform: uppercase;
}