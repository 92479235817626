@import "swiper/scss";
@import "swiper/scss/scrollbar";

.slider{
  height: 600px;
  position: relative;
  width: 100%;
}

.slider__content{
  display: flex;
}

.slider__media{
  width: 65%;
  height: 600px;

  img{
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.slider__text{
  background-color: var(--color-blue-2);
  height: 340px;
  width: 35%;
}

.slider__thumb{
  bottom: 0;
  height: 260px;
  position: absolute;
  right: 0;
  width: 35%;
}

.slide{
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 27px 15% 27px 5%;
}

.slide__title{
  border-bottom: 1px solid var(--color-blue-1);
  font-weight: 400;
  font-size: 1.333rem;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.slide__text{
  color: var(--color-blue-3);
}

.slide__action{
  align-self: end;
  color: var(--color-blue-1);

  &::before{
    content: '→ ';
  }
}

.slide__thumb{
  background-color: var(--color-cyan-2);
  box-sizing: border-box;
  cursor: pointer;
  padding: 3%;

  &:hover{
    img{
      opacity: .85;
    }
  }

  &:nth-of-type(odd){
    background-color: var(--color-cyan-1);
  }

  &.swiper-slide-thumb-active{
    img{
      opacity: 1;
    }
  }

  img{
    opacity: .7;
    transition: opacity .5s;
  }
}

.slide__preview{
  color: var(--color-blue-2);
  font-family: var(--font-secondary);
  font-size: 0.778rem;
  text-transform: uppercase;
}