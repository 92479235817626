.secret{
  background-color: var(--color-cyan-2);
  padding-bottom: 120px;
  padding-top: 120px;
}

.secret__header{
  align-items: center;
  border-bottom: 9px solid var(--color-blue-2);
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
  padding: 12px 2%;
}

.secret__title{
  font-size: 1.333rem;
  font-weight: 400;
}

.secret__items{
  display: grid;
  font-family: var(--font-secondary);
  font-weight: 100;
  font-size: 6.667rem;
  grid-column-gap: 2%;
  grid-row-gap: 2%;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 2%;
  padding-right: 2%;
}

.secret__item{
  --opacity: 0;

  aspect-ratio: 29 / 21;
  position: relative;

  span{
    color: var(--color-white);
    left: 50%;
    mix-blend-mode: difference;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
  }
}

.secret__item--active{
  .secret__opera{
    opacity: 1;
  }
}

.secret__item--strong{
  font-weight: 300;
}

.secret__opera{
  opacity: var(--opacity);
  //transition: opacity .9s;
}