.edition{
  background-color: var(--color-cyan-1);
  display: grid;
  grid-template-columns: 65% 1fr;
  margin-bottom: 12px;
}

.edition__content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 45px 10%;
}

.edition__title{
  color: var(--color-blue-3);
  font-size: 2.056rem;
}

.edition__text{
  p{
    font-size: 0.778rem;
    margin-bottom: 18px;
  }
}